import { Link } from "react-router-dom";
import logo from "../../img/logo/logo.xml"
import styles from "./styles.module.scss"
import logoWHITE from "../../img/logo/logos/okrosWHITE.png"
import logoGOLD from "../../img/logo/logos/okrosGOLD.png"
const Footer = ({ language }) => {
    return (
        <div className={styles.footer}>
            <section className={styles.footer_left}>
                <ul className={styles.footer_left_list}>
                    <li className={styles.footer_left_item} id={styles.logo__wrapper}>
                        <Link className={styles.footer_left_link} to="/">
                            <img src={logoWHITE} className={styles.logo} alt="" />
                            {/* <iframe src={logo} width="200px" className={styles.logo}></iframe> */}
                        </Link>
                    </li>
                    <li className={styles.footer_left_item}>{language === 0 ? '+ 7 (968) 337-00-00' : 'Ваш номер телефона'}</li>
                    <li className={styles.footer_left_item}>
                        <a className={styles.footer_left_link} id={styles.link_mail} href="mailto:okros-contact@mail.ru">
                            okros.info@mail.ru
                        </a>
                    </li>
                </ul>
            </section>
            <section className={styles.footer_right}>
                <ul className={styles.footer_right_list}>
                    <li className={styles.footer_right_item}><Link to="/" className={styles.footer_right_link}>{language === 0 ? 'Home' : 'Главная'}</Link></li>
                    <li className={styles.footer_right_item}><Link to="/about" className={styles.footer_right_link}>{language === 0 ? 'About Us' : 'О компании'}</Link></li>
                    <li className={styles.footer_right_item}><Link to="/" className={styles.footer_right_link}>{language === 0 ? 'Products' : 'Продукция'}</Link></li>
                    <li className={styles.footer_right_item}><Link to="/contacts" className={styles.footer_right_link}>{language === 0 ? 'Contact Us' : 'Связь с нами'}</Link></li>
                </ul>
            </section>

        </div>
    );
}

export default Footer;
import Navbar from "../../components/Navbar/Navbar";
import styles from "./styles.module.scss"
import aboutIMG from "../../img/about/about.jpg"
import Footer from "../../components/footer/Footer";
const About = ({ language }) => {
    const aboutTextENG = '';
    return (
        <>
            <main className={styles.main}>
                <Navbar language={language} />
                <div className={styles.about}>
                    <div className={styles.about__text__wrapper}>
                        <h1 className={styles.about__title}>{language === 0 ? `About us` : `О нас`}</h1>
                        <p className={styles.about__text}>
                            {language === 0 ? `OOO "Okros" is an innovative oil trading company specializing in the trade of crude oil, oil products, and LPG.` : `ООО «Окрос» является инновационной нефтетрейдинговой компанией, специализирующейся на торговле сырой нефтью, нефтепродуктами и СУГ.`}
                            <span style={{ fontWeight: "bold" }}>
                                {language === 0 ? `Our main advantage lies in the direct connection between sellers and buyers, ensuring the delivery of high-quality products and eliminating unnecessary costs.` : `Главное наше преимущество – прямая связь между продавцами и покупателями, которая гарантирует поставку качественной продукции и ликвидирует лишние затраты.`}
                            </span>
                            <br /><br />
                            <span style={{ fontWeight: "bold" }}>
                                {language === 0 ? `The goal of OOO "Okros" is to meet the needs of our customers by providing high-quality services and uninterrupted product delivery.` : `Целью ООО «Окрос» является удовлетворение потребностей наших клиентов за счет предоставления высококачественных услуг и бесперебойной поставки продукции.`}
                            </span>
                            <br /><br />
                            {language === 0 ? `We aim to build long-term and mutually beneficial relationships with our clients, offering the most effective solutions regardless of volume and complexity.` : `Мы стремимся строить долгосрочные и взаимовыгодные отношения с нашими клиентами, предлагая самые эффективные решения вне зависимости от объема и сложности.`}
                            <br /><br />
                            {language === 0 ? `Our company's strategy is focused on expanding the range of products and services we offer, as well as developing a network of reliable partners, ensuring fast delivery, achieving set goals, and ensuring success.` : `Стратегия нашей компании направлена на расширение ассортимента предлагаемых нами продуктов и услуг, а также на развитие сети надежных партнеров, обеспечение быстрой доставки, достижение поставленных целей и обеспечение успеха.`}
                        </p>
                    </div>
                    <aside className={styles.about__img__wrapper}>
                        <img className={styles.about__img} src={aboutIMG} alt="About photo" />
                    </aside>
                </div>
                <div className={styles.about__blocks_wrapper}>
                    <div className={styles.about_blocks_item}>
                        <h2 className={styles.about_blocks_title}>{language === 0 ? 'Export' : 'Экспорт'}</h2>
                        <p className={styles.about_blocks_text}>
                            {language === 0
                                ? 'Okros carries out the sale of products to foreign markets at mutually beneficial prices.'
                                : 'Окрос осуществляет реализацию продукции на зарубежные рынки по взаимовыгодной цене.'}
                        </p>
                    </div>
                    <div className={styles.about_blocks_item}>
                        <h2 className={styles.about_blocks_title}>{language === 0 ? 'Import' : 'Импорт'}</h2>
                        <p className={styles.about_blocks_text}>
                            {language === 0
                                ? 'Okros facilitates the import of products to the domestic market from resources of foreign partners.'
                                : 'Окрос способствует ввозу продукции на внутренний рынок с ресурсов зарубежных партнеров.'}
                        </p>
                    </div>
                    <div className={styles.about_blocks_item}>
                        <h2 className={styles.about_blocks_title}>{language === 0 ? 'Logistics' : 'Логистика'}</h2>
                        <p className={styles.about_blocks_text}>
                            {language === 0
                                ? 'Our company collaborates with logistics companies through long-term contracts, ensuring the guaranteed supply of oil, oil products, and LPG to the end consumer.'
                                : 'Наша компания взаимодействует с логистическими компаниями по долгосрочным контрактам, что способствует гарантированному обеспечению поставки нефти, нефтепродуктов и СУГ конечному потребителю.'}
                        </p>
                    </div>
                    <Footer language={language} />
                </div>
                

                <section className={styles.markets} style={{ display: "none" }}>
                    <div className={styles.markets__title_wrapper}>
                        <h1>Наши рынки</h1>
                    </div>
                    
                </section>
                
            </main>
            
        </>
    );
}

export default About;
import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/footer/Footer";
import seraPDF from "./documents/sera.pdf";
import styles from "./styles.module.scss";

import ai80k2 from "./documents/AI80K2.pdf";
import AI92K4 from "./documents/AI92K4.pdf";
import AI92K5 from "./documents/AI92k5.pdf";
import AI95K4 from "./documents/AI95K4.pdf"
import AI95K5 from "./documents/AI95K5.pdf"
import benzgazstabil from "./documents/benzgazstabil.pdf";
import bitum6090 from "./documents/Bitum6090.pdf";
import DT3K5 from "./documents/DT3K5.pdf";
import DTEK5 from "./documents/DTEK5.pdf";
import DTLK5 from "./documents/DTLK5.pdf";
import gazPT from "./documents/gazPT.pdf";
import gudron from "./documents/gudron.pdf";
import MAZUT2 from "./documents/MAZUT2.pdf";
import MAZUT25 from "./documents/MAZUT25.pdf";
import toplivoPT from "./documents/toplivoPT.pdf";

const Orsknefteorgsintez = ({ language, type }) => {
    function check() {
        console.log(type)
    };
    return (

        <>
            <Navbar language={language} />
            <main className={styles.main}>
                <h1></h1>
                <ul className={styles.product_list}>
                    <li className={styles.product_item}>
                        <h1 className={styles.prduct_item_title}><span className={styles.title_span}>Бензин</span></h1>
                        <ul className={styles.product_item_list}>
                            <li className={styles.product_item_card}><p>Автомобильный бензин неэтилированный марки АИ-92 экологического класса К4 (АИ-92-К4)</p>
                                <span className={styles.span_pass}><a className={styles.pass_btn} href={AI92K4}>ПАСПОРТ КАЧЕСТВА</a></span>
                            </li>
                            <li className={styles.product_item_card}><p>Автомобильный бензин неэтилированный марки АИ-92 экологического класса К5 (АИ-92-К5)</p>
                                <span className={styles.span_pass}><a className={styles.pass_btn} href={AI92K5}>ПАСПОРТ КАЧЕСТВА</a></span>
                            </li>
                            <li className={styles.product_item_card}><p>Автомобильный бензин неэтилированный марки АИ-95 экологического класса К5 (АИ-95-К5)</p>
                                <span className={styles.span_pass}><a className={styles.pass_btn} href={AI95K5}>ПАСПОРТ КАЧЕСТВА</a></span>
                            </li>
                            <li className={styles.product_item_card}><p>Бензин неэтилированный экспортный марки Нормаль-80 (АИ-80-К2) </p>
                                <span className={styles.span_pass}><a className={styles.pass_btn} href={ai80k2}>ПАСПОРТ КАЧЕСТВА</a></span>
                            </li>
                            <li className={styles.product_item_card}><p>Бензин газовый стабильный</p>
                                <span className={styles.span_pass}><a className={styles.pass_btn} href={benzgazstabil}>ПАСПОРТ КАЧЕСТВА</a></span></li>
                        </ul>
                    </li>
                    <li className={styles.product_item}>
                        <h1 className={styles.prduct_item_title}><span className={styles.title_span}>Дизельное и реактивное топливо</span></h1>
                        <ul className={styles.product_item_list}>
                            <li className={styles.product_item_card}><p>Дизельное топливо ЕВРО, летнее, сорта С, экологического класса К5 марки ДТ-Л-К5</p>
                                <span className={styles.span_pass}><a className={styles.pass_btn} href={DTLK5}>ПАСПОРТ КАЧЕСТВА</a></span></li>
                            <li className={styles.product_item_card}><p>Дизельное топливо ЕВРО, межсезонное, сорта Е, экологического класса К5 марки ДТ-Е-К5</p>
                                <span className={styles.span_pass}><a className={styles.pass_btn} href={DTEK5}>ПАСПОРТ КАЧЕСТВА</a></span></li>
                            <li className={styles.product_item_card}><p>Топливо дизельное зимнее ДТ-З-К5 минус 32</p>
                                <span className={styles.span_pass}><a className={styles.pass_btn} href={DT3K5}>ПАСПОРТ КАЧЕСТВА</a></span></li>
                            <li className={styles.product_item_card}><p>Топливо для реактивных двигателей марки РТ, высший сорт</p>
                                <span className={styles.span_pass}><a className={styles.pass_btn} href={toplivoPT}>ПАСПОРТ КАЧЕСТВА</a></span></li>
                        </ul>
                    </li>
                    <li className={styles.product_item}>
                        <h1 className={styles.prduct_item_title}><span className={styles.title_span}>Мазут и битум</span></h1>
                        <ul className={styles.product_item_list}>
                            <li className={styles.product_item_card}><p>Мазут топочный 100, 2,00%, зольный, 25°С</p>
                                <span className={styles.span_pass}><a className={styles.pass_btn} href={MAZUT2}>ПАСПОРТ КАЧЕСТВА</a></span></li>
                            <li className={styles.product_item_card}><p>Мазут топочный 100, 2,50%, зольный, 25°С</p>
                                <span className={styles.span_pass}><a className={styles.pass_btn} href={MAZUT25}>ПАСПОРТ КАЧЕСТВА</a></span></li>
                            <li className={styles.product_item_card}><p>Битум нефтяной дорожный вязкий марки БНД 6090</p>
                                <span className={styles.span_pass}><a className={styles.pass_btn} href={bitum6090}>ПАСПОРТ КАЧЕСТВА</a></span></li>
                        </ul>
                    </li>
                    <li className={styles.product_item}>
                        <h1 className={styles.prduct_item_title}><span className={styles.title_span}>Газ сжиженный</span></h1>
                        <ul className={styles.product_item_list}>
                            <li className={styles.product_item_card}><p>Газы углеводородные сжиженные топливные марки пропан технический (ПТ)</p>
                                <span className={styles.span_pass}><a className={styles.pass_btn} href={gazPT}>ПАСПОРТ КАЧЕСТВА</a></span></li>
                                <li className={styles.product_item_card}></li>
                        </ul>
                    </li>
                    <li className={styles.product_item}>
                        <h1 className={styles.prduct_item_title}><span className={styles.title_span}>Прочие нефтепродукты</span></h1>
                        <ul className={styles.product_item_list}>
                            <li className={styles.product_item_card}><p>Гудрон нефтяной</p>
                                <span className={styles.span_pass}><a className={styles.pass_btn} href={gudron}>ПАСПОРТ КАЧЕСТВА</a></span></li>
                            <li className={styles.product_item_card}><p>Сера техническая газовая, гранулированная, сорт 9998</p>
                                <span className={styles.span_pass}><a className={styles.pass_btn} href={seraPDF}>ПАСПОРТ КАЧЕСТВА</a></span></li>
                        </ul>
                    </li>
                    {/* <button onClick={check}>Check</button> */}
                </ul>
                <Footer language={language} />
            </main>

        </>
    );
};


export default Orsknefteorgsintez;
import React, { useState } from 'react';
import styles from "./styles.module.scss"
import success from "../../img/form/success.png"

const FeedbackForm = ({language}) => {
  const [countryCode, setCountryCode] = useState('+1');
  const [phoneNumber, setPhoneNumber] = useState('');

  const handleCountryCodeChange = (e) => {
    setCountryCode(e.target.value);
  };

  const handlePhoneNumberChange = (e) => {
    const formattedPhoneNumber = formatPhoneNumber(e.target.value) || ''; // Добавлено || ''
    setPhoneNumber(formattedPhoneNumber);
  };

  const formatPhoneNumber = (input) => {
    const cleaned = input.replace(/\D/g, '');

    // Применяем формат "(XXX) XXX XX-XX"
    const match = cleaned.match(/^(\d{0,3})(\d{0,3})(\d{0,2})(\d{0,2})$/);
    if (match) {
      const formatted = `(${match[1]}) ${match[2]} ${match[3]}-${match[4]}`;
      return formatted.trim();
    }

    return input;
  };
  const [successVisible, setSuccessVisible] = useState('none');
  const [formVisible, setFormVisible] = useState('block')
  function sendFrom(e) {
    e.preventDefault();
    const name = 'Просьбюа об обратном звонке';
    const email = 'Просьбюа об обратном звонке';
    const message = `Просьба позвониотьо на этот номер телефона: ${countryCode}` + `${phoneNumber}`
    fetch("/send_email.php", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ name, email, message }),
    })
      .then((response) => response.text())
      .then((data) => {
        console.log(data);
      })
      .catch((error) => {
        console.error("Ошибка:", error);
      });
      setFormVisible('none');
      setSuccessVisible('block')
  }


  return (
    <>
      <form className={styles.form} style={{ display: `${formVisible}`}}> 
        <div className={styles.form_wrapper}>
          <div className={styles.phoneNuberWrapper}>
            <select
              value={countryCode}
              onChange={handleCountryCodeChange}
              className={styles.countryCodeSelect}
            >
              <option value="+1">+1</option>
              <option value="+44">+44</option>
              <option value="+33">+33</option>
              <option value="+49">+49</option>
              <option value="+81">+81</option>
              <option value="+86">+86</option>
              <option value="+91">+91</option>
              <option value="+7">+7</option>
              <option value="+55">+55</option>
              <option value="+966">+966</option>
              <option value="+20">+20</option>
              <option value="+81">+81</option>
              <option value="+82">+82</option>
              <option value="+34">+34</option>
              <option value="+39">+39</option>
              <option value="+51">+51</option>
              <option value="+61">+61</option>
              <option value="+82">+82</option>
              <option value="+46">+46</option>
              <option value="+65">+65</option>
              <option value="+92">+92</option>
              <option value="+62">+62</option>
              <option value="+27">+27</option>
              <option value="+91">+91</option>
              <option value="+90">+90</option>
              <option value="+33">+33</option>
              <option value="+49">+49</option>
              <option value="+81">+81</option>
              <option value="+86">+86</option>
              <option value="+92">+92</option>
              <option value="+880">+880</option>
              <option value="+86">+86</option>
              <option value="+84">+84</option>
              <option value="+82">+82</option>
              <option value="+62">+62</option>
              <option value="+66">+66</option>
              <option value="+65">+65</option>
              <option value="+60">+60</option>
              <option value="+81">+81</option>
              <option value="+84">+84</option>
              <option value="+66">+66</option>
              <option value="+84">+84</option>
              <option value="+852">+852</option>
              <option value="+886">+886</option>
              <option value="+95">+95</option>
              <option value="+92">+92</option>
              <option value="+855">+855</option>
              <option value="+62">+62</option>
              <option value="+63">+63</option>
              <option value="+880">+880</option>
              <option value="+91">+91</option>
              <option value="+92">+92</option>
              <option value="+98">+98</option>
              <option value="+964">+964</option>
              <option value="+972">+972</option>
              <option value="+965">+965</option>
              <option value="+966">+966</option>
              <option value="+971">+971</option>
              <option value="+967">+967</option>
              <option value="+968">+968</option>
              <option value="+961">+961</option>
              <option value="+962">+962</option>
              <option value="+963">+963</option>
              <option value="+970">+970</option>

            </select>

            {/* Ввод номера телефона */}
            <input
              type="text"
              value={phoneNumber}
              onChange={handlePhoneNumberChange}
              placeholder="(999) 999 99-99"
              className={styles.phoneNumberInput}
            />
          </div>

          {/* Кнопка отправки */}
          <button className={styles.form_button} onClick={(e) => sendFrom(e)}>
          {language === 0 ? 'Submit' : 'Отправить'}
          </button>
        </div>
      </form>
      <div className={styles.from_comp} style={{ display: `${successVisible}` }}>
        <h1 className={styles.from_comp_text}>Отправленно!</h1>
        <img src={success} alt="Success!" id={styles.success_img} />
      </div>
    </>
  );
};

export default FeedbackForm;

import Navbar from "../../components/Navbar/Navbar";
import React, { useEffect, useState } from "react";
import styles from "./styles.module.scss"
import Footer from "../../components/footer/Footer";
const LocationFrom = ({ language }) => {
    // const [name, setName] = useState('');
    // const [email, setEmail] = useState('');
    // const [message, setMessage] = useState('');

    // // const handleSubmit = (e) => {
    // //     e.preventDefault();


    // // };
    // const [formNAME, setFormNAME] = useState('Name');
    // const [formMASSAGE, setFormMASSAGE] = useState('Massage');
    // function fromTranslate() {
    //     if (language) {
    //         setFormMASSAGE('Massage');
    //         setFormNAME('Name');
    //     } else {
    //         setFormMASSAGE('Сообщение');
    //         setFormNAME('Имя');
    //     }
    // }
    // useEffect(() => {
    //     fromTranslate();
    // }, []);





    const phrases = {
        en: {
            formTitle: 'Contact Us',
            namePlaceholder: 'Name',
            emailPlaceholder: 'Email',
            messagePlaceholder: 'Message',
            nameWarning: 'Please enter your name',
            emailWarning: 'Please enter a valid email',
            messageWarning: 'Please enter a message',
            submitButton: 'Submit',
        },
        ru: {
            formTitle: 'Связь с нами',
            namePlaceholder: 'Имя',
            emailPlaceholder: 'Email',
            messagePlaceholder: 'Сообщение',
            nameWarning: 'Введите ваше имя',
            emailWarning: 'Введите корректный email',
            messageWarning: 'Введите сообщение',
            submitButton: 'Отправить',
        },
    };

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [nameError, setNameError] = useState(false);
    const [emailError, setEmailError] = useState(false);
    const [messageError, setMessageError] = useState(false);

    const handleSubmit = (e) => {
        e.preventDefault();

        if (!name) {
            setNameError(true);
        } else {
            setNameError(false);
        }

        if (!email || !validateEmail(email)) {
            setEmailError(true);
        } else {
            setEmailError(false);
        }

        if (!message) {
            setMessageError(true);
        } else {
            setMessageError(false);
        }

        // Если все обязательные поля заполнены, отправляем форму
        if (name && email && validateEmail(email) && message) {
            fetch("/send_email.php", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ name, email, message }),
            })
                .then((response) => response.text())
                .then((data) => {
                    console.log(data);
                })
                .catch((error) => {
                    console.error("Ошибка:", error);
                });
        }
    };

    // Проверка корректности email
    const validateEmail = (email) => {
        const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return re.test(email);
    };
    return (
        <>

            <form className={styles.contact_form} onSubmit={handleSubmit}>
            <div className={styles.form_btn_wrapper}>
                <h2 className={styles.location_from_title}>
                    {language === 0 ? "Have questions?" : "Есть вопросы?"}
                </h2>
                </div>
                <div className={styles.form_wrapper}>
                    <div className={styles.locationFrom_wrapper}>
                        <input
                            type="text"
                            id={styles.name}
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            required
                            placeholder={
                                language === 0
                                    ? phrases.en.namePlaceholder
                                    : phrases.ru.namePlaceholder
                            }
                            className={nameError ? 'error' : ''}
                        />
                        {nameError && (
                            <p className={styles.error_message}>
                                {language === 0 ? phrases.en.nameWarning : phrases.ru.nameWarning}
                            </p>
                        )}
                        <input
                            type="email"
                            id={styles.email}
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                            placeholder={
                                language === 0
                                    ? phrases.en.emailPlaceholder
                                    : phrases.ru.emailPlaceholder
                            }
                            className={emailError ? 'error' : ''}
                        />

                        {emailError && (
                            <p className={styles.error - message}>
                                {language === 0
                                    ? phrases.en.emailWarning
                                    : phrases.ru.emailWarning}
                            </p>
                        )}
                    </div>
                    <textarea
                        id={styles.message}
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        required
                        placeholder={
                            language === 0
                                ? phrases.en.messagePlaceholder
                                : "Вопрос"
                        }
                        className={messageError ? 'error' : ''}
                    ></textarea>
                    {messageError && (
                        <p className={styles.error_message}>
                            {language === 0
                                ? phrases.en.messageWarning
                                : phrases.ru.messageWarning}
                        </p>
                    )}
                </div>
                <div className={styles.form_btn_wrapper}>
                    <button id={styles.form__btn} type="submit">
                        {language === 0 ? phrases.en.submitButton : phrases.ru.submitButton}
                    </button>
                </div>
            </form>

            {/* <Footer language={language}/> */}
        </>
    );
};
export default LocationFrom;
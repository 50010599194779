import React, { useState } from 'react';
import styles from './styles.module.scss';
import { Link } from 'react-router-dom';
import logo from "../../img/logo/logo.xml"
import logoWHITE from "../../img/logo/logos/okrosWHITE.png"
import logoGOLD from "../../img/logo/logos/okrosGOLD.png"
// import logoSVG from "../../img/logo/OKROS.svg"

const Navbar = ({ language }) => {
    const [burgerVisibility, setBurgerVisibility] = useState("none");
    const [burgerTrasform, setBurgerTrasform] = useState('-100%');
    const [isBurgerOpen, setIsBurgerOpen] = useState(false);
    function burgerBtn() {
        setIsBurgerOpen(!isBurgerOpen);
        if (burgerVisibility === "none") {
            setBurgerVisibility("flex");
            setTimeout(() => {
                setBurgerTrasform('0');
            }, 0);
        } else {
            setBurgerTrasform('-100%');
            setTimeout(() => {
                setBurgerVisibility("none");
            }, 300);
        }
    }
    const xmlString = "<example><item>Item 1</item><item>Item 2</item></example>";
    const parser = new DOMParser();
    const xmlDoc = parser.parseFromString(xmlString, "text/xml");

    const [isOpen, setIsOpen] = useState(false);

    const handleClick = () => {
        setIsOpen(!isOpen);
        burgerBtn();
    };
    const [isHovered, setIsHovered] = useState(false);

    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };
    const [isOpenMob, setIsOpenMob] = useState(false);
    // const handleClickMob  = () => {
    //     setIsOpen(!isOpen);
    //     mobBurgerBtn();  
    // };

    const [isProductsOpen, setProductsOpen] = useState(false);

    const toggleProducts = () => {
      setProductsOpen(!isProductsOpen);
    };

    return (
        <header className={styles.header}>
            <nav className={styles.nav}>
                {/* <iframe src={logo} width="200px" className={styles.logo}></iframe> */}
                <img src={logoWHITE} className={styles.logo} alt="" />
                <ul className={styles.ul}>
                    <li className={styles.li}>
                        <Link to='/' className={styles.li__text}>
                            {language === 0 ? 'Home' : 'Главная'}
                        </Link>
                    </li>
                    <li className={styles.li}>
                        <Link to='/about' className={styles.li__text}>
                            {language === 0 ? 'About Us' : 'О нас'}
                        </Link>
                    </li>
                    <li className={styles.li}>
                        <Link to='/location' className={styles.li__text}>
                            {language === 0 ? 'Contact us' : 'Связь с нами'}
                        </Link>
                    </li>
                    {/* <li className={styles.li}>
                        <Link to='/contacts' className={styles.li__text}>
                            {language === 0 ? 'Contact' : 'Контакты'}
                        </Link>
                    </li> */}
                    <li className={styles.li} id={styles.products}
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                        style={{ backgroundColor: isHovered ? 'rgba(0, 0, 0, 0.459)' : 'rgba(0, 0, 0, 0)' }}
                    >
                        <Link className={styles.li__text}>
                            {language === 0 ? 'Products' : 'Продукция'}
                        </Link>
                        <div className={styles.products__burger} style={{ height: isHovered ? '200px' : '0px' }}>
                            <ul className={styles.product_list}>
                                <li className={styles.product__item}>
                                    <Link to="/Orsknefteorgsintez" className={styles.product_link}>ПАО «Орскнефтеоргсинтез»</Link>
                                </li>
                                <li className={styles.product__item}>
                                    <Link to="/Taneko" className={styles.product_link}>АО «Танеко»</Link>
                                </li>
                                <li className={styles.product__item}>
                                    <Link className={styles.product_link}>АО «ТАИФ-НК»</Link>
                                </li>
                            </ul>
                        </div>
                    </li>
                </ul>
                <button className={`${styles.burger} ${isOpen ? styles.burgerOpen : ''}`} onClick={handleClick}>
                    <span className={styles.burgerLine}></span>
                    <span className={styles.burgerLine}></span>
                    <span className={styles.burgerLine}></span>
                </button>
            </nav>
            <nav className={styles.burger_nav} style={{ display: burgerVisibility, transform: `translateY(${burgerTrasform})` }}>
            <img src={logoGOLD} id={styles.logoMOB} className={styles.logo} alt="" />
                <hr className={styles.burger__hr} />
                <ul className={styles.burger_ul}>

                    <li className={styles.burger_li}>
                        <Link to='/' className={styles.li__text}>
                            {language === 0 ? 'Home' : 'Главная'}
                        </Link>
                    </li>
                    <li className={styles.burger_li}>
                        <Link to='/about' className={styles.li__text}>
                            {language === 0 ? 'About Us' : 'О нас'}
                        </Link>
                    </li>
                    <li className={styles.burger_li}>
                        <Link to='/location' className={styles.li__text}>
                            {language === 0 ? 'Contact us' : 'Связь с нами'}
                        </Link>
                    </li>
                    {/* <li className={styles.burger_li}>
                        <Link to='/contacts' className={styles.li__text}>
                            {language === 0 ? 'Contact' : 'Контакты'}
                        </Link>
                    </li> */}
                    <li className={styles.burger_li}>
                        <Link className={styles.li__text} onClick={toggleProducts}>
                            {language === 0 ? 'Products' : 'Продукция'}
                        </Link>
                        <div
                            className={`${styles.products__burger_mob} ${isProductsOpen ? styles.open : ''
                                }`}
                        >
                            <ul className={styles.product_list}>
                                <li className={styles.product__item} id={styles.product__item_mob}>
                                    <Link to="/Orsknefteorgsintez" className={styles.product_link} id={styles.product_link_mob}>
                                        {language === 0 ? 'OJSC «Orsknefteorgsintez»' : 'ПАО «Орскнефтеоргсинтез»'}
                                    </Link>
                                </li>
                                <li className={styles.product__item} id={styles.product__item_mob}>
                                    <Link className={styles.product_link} id={styles.product_link_mob}>
                                    {language === 0 ? 'JSC «Taneko»' : 'АО «Танеко» '}
                                    </Link>
                                </li>
                                <li className={styles.product__item} id={styles.product__item_mob}>
                                    <Link className={styles.product_link} id={styles.product_link_mob}>
                                    {language === 0 ? 'JSC «TAIF-NK»' : 'АО «ТАИФ-НК»'}
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </li>
                </ul>
            </nav>
        </header>
    );
}

export default Navbar;

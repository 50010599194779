import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/footer/Footer";
import styles from "./styles.module.scss";

// import ai80k2 from "./documents/AI80K2.pdf";
// import AI92K4 from "./documents/AI92K4.pdf";
// import AI92K5 from "./documents/AI92k5.pdf";
// import AI95K4 from "./documents/AI95K4.pdf"
// import AI95K5 from "./documents/AI95K5.pdf"
// import benzgazstabil from "./documents/benzgazstabil.pdf";
// import bitum6090 from "./documents/Bitum6090.pdf";
// import DT3K5 from "./documents/DT3K5.pdf";
// import DTEK5 from "./documents/DTEK5.pdf";
// import DTLK5 from "./documents/DTLK5.pdf";
// import gazPT from "./documents/gazPT.pdf";
// import gudron from "./documents/gudron.pdf";
// import MAZUT2 from "./documents/MAZUT2.pdf";
// import MAZUT25 from "./documents/MAZUT25.pdf";
// import toplivoPT from "./documents/toplivoPT.pdf";
import AI92K5 from "./documents/AI92K5.pdf"
import AI95K5 from "./documents/AI95K5.pdf"
import DT3K5 from "./documents/DT3K5.pdf"
import DTLK5 from "./documents/DTLK5.pdf"
import sera from "./documents/sera.pdf"
import toplivoPT from "./documents/toplivoPT.pdf"
const Taneko = ({ language }) => {
    return (
        <>
            <Navbar language={language} />
            <main className={styles.main}>
                <h1></h1>
                <ul className={styles.product_list}>
                    <li className={styles.product_item}>
                        <h1 className={styles.prduct_item_title}><span className={styles.title_span}>Бензин</span></h1>
                        <ul className={styles.product_item_list}>
                            <li className={styles.product_item_card}><p>Автомобильный бензин АИ-92-К5 экологического класса К5 марки TANECO-92 "EURO-6" по СТО 78689379-50-2020</p>
                                <span className={styles.span_pass}><a href={AI92K5} className={styles.pass_btn}>ПАСПОРТ КАЧЕСТВА</a></span>
                            </li>
                            <li className={styles.product_item_card}><p>Автомобильный бензин АИ-95-К5 экологического класса К5 марки TANECO-95 "EURO-6" по СТО 78689379-50-2020</p>
                                <span className={styles.span_pass}><a href={AI95K5} className={styles.pass_btn}>ПАСПОРТ КАЧЕСТВА</a></span>
                            </li>
                        </ul>
                    </li>
                    <li className={styles.product_item}>
                        <h1 className={styles.prduct_item_title}><span className={styles.title_span}>Дизельное и реактивное топливо</span></h1>
                        <ul className={styles.product_item_list}>
                            <li className={styles.product_item_card}><p>Дизельное топливо ДТ-А-К5 TANECO "EURO-6", арктическое, класса 4, экологического класса К5 по СТО 78689379-51-2020</p>
                                <span className={styles.span_pass}><a className={styles.pass_btn}>ПАСПОРТ КАЧЕСТВА</a></span>
                            </li>
                            <li className={styles.product_item_card}><p>Топливо дизельное зимнее ДТ-З-К5 (минус 32) экологический класс К5 по СТО 78689379-40-2019</p>
                                <span className={styles.span_pass}><a href={DT3K5} className={styles.pass_btn}>ПАСПОРТ КАЧЕСТВА</a></span>
                            </li>
                            <li className={styles.product_item_card}><p>Топливо дизельное ЕВРО, летнее, сорта С, экологического класса К5 по ГОСТ 32511-2013 / ДТ-Л-К5</p>
                                <span className={styles.span_pass}><a href={toplivoPT} className={styles.pass_btn}>ПАСПОРТ КАЧЕСТВА</a></span>
                            </li>
                            <li className={styles.product_item_card}><p>Топливо дизельное ЕВРО, межсезонное, сорта E, экологического класса К5 по ГОСТ 32511-2013 / ДТ-Е-К5</p>
                                <span className={styles.span_pass}><a className={styles.pass_btn}>ПАСПОРТ КАЧЕСТВА</a></span>
                            </li>
                            <li className={styles.product_item_card}><p>Дизельное топливо ДТ-Е-К5 TANECO "EURO-6", межсезонное, сорта Е, экологического класса К5 по СТО 78689379-51-2020</p>
                                <span className={styles.span_pass}><a href={DTLK5} className={styles.pass_btn}>ПАСПОРТ КАЧЕСТВА</a></span>
                            </li>
                            <li className={styles.product_item_card}><p>Топливо для реактивных двигателей марки ТС-1 высший сорт, ГОСТ 10227-86</p>
                                <span className={styles.span_pass}><a href={toplivoPT} className={styles.pass_btn}>ПАСПОРТ КАЧЕСТВА</a></span>
                            </li>
                        </ul>
                    </li>
                    <li className={styles.product_item}>
                        <h1 className={styles.prduct_item_title}><span className={styles.title_span}>Прочие нефтепродукты</span></h1>
                        <ul className={styles.product_item_list}>
                            <li className={styles.product_item_card}><p>Сера техническая газовая гранулированная, сорт 9998, ТУ 2112-144-31323949-2010</p>
                                <span className={styles.span_pass}><a href={sera} className={styles.pass_btn}>ПАСПОРТ КАЧЕСТВА</a></span>
                            </li>
                            <li className={styles.product_item_card}><p></p>
                                
                            </li>
                        </ul>
                    </li>
                    {/* <button onClick={check}>Check</button> */}
                </ul>
                <Footer language={language} />
            </main>

        </>
    );
};


export default Taneko;